import React from 'react';
import { Button, Col, Row } from 'antd';
import { reduxForm } from 'redux-form';
import t from '@/utilities/translate';
import FormSkeleton from '@/components/common/form/FormSkeleton';
import FormField from '@/components/common/form/DefaultFormWrapper';
import DatePickerWrapper from '@/components/common/form/DatePickerWrapper';
import { SERVER_DATE_TIME_FORMAT } from '@/constants/date-time';

const Form = ({ handleSubmit, pending, pristine, defaultValue }) => {
	return (
		<FormSkeleton onSubmit={handleSubmit} hideBottomBar={true}>
			<Row gutter={15}>
				<Col xs={24} style={{ marginBottom: 16 }}>
					<FormField
						component={DatePickerWrapper}
						colSpan={24}
						afterSpan={0}
						defaultValue={defaultValue}
						dateFormat={SERVER_DATE_TIME_FORMAT}
						showTime={true}
						name="date"
						label={t('deal/date/label')}
						placeholder={t('deal/date/label')} />
				</Col>
				<Col xs={24}>
					<Button type="primary"
							disabled={pristine}
							loading={pending}
							htmlType="submit"
							style={{ width: '100%' }}
					>
						{t('deal-change-fiscal-year/submit/button')}
					</Button>
				</Col>
			</Row>
		</FormSkeleton>
	)
};

export default reduxForm({
	form: 'ChangeDate',
})(Form);
