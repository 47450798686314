export const FETCH_NEXT_ACTIONS_LIST = '@/deal/FETCH_NEXT_ACTIONS_LIST';
export const SET_NEXT_ACTIONS_LIST = '@/deal/SET_NEXT_ACTIONS_LIST';

export const FETCH_DEALS_LIST = '@/deal/FETCH_DEALS_LIST';
export const SET_DEALS_LIST = '@/deal/SET_DEALS_LIST';

export const EDIT_DEAL = '@/deal/EDIT_DEAL';
export const DELETE_DEAL = '@/deal/DELETE_DEAL';

export const FETCH_ARCHIVED_DEALS = '@/deal/FETCH_ARCHIVED_DEALS';
export const SET_ARCHIVED_DEALS = '@/deal/SET_ARCHIVED_DEALS';

export const FETCH_STATUSES_ACTIVE_LIST = '@/deal/FETCH_STATUSES_ACTIVE_LIST';
export const SET_STATUSES_ACTIVE_LIST = '@/deal/SET_STATUSES_ACTIVE_LIST';

export const FETCH_RESULTS_ACTIVE_LIST = '@/deal/FETCH_RESULTS_ACTIVE_LIST';
export const SET_RESULTS_ACTIVE_LIST = '@/deal/SET_RESULTS_ACTIVE_LIST';

export const FETCH_ACTIONS_ACTIVE_LIST = '@/deal/FETCH_ACTIONS_ACTIVE_LIST';
export const SET_ACTIONS_ACTIVE_LIST = '@/deal/SET_ACTIONS_ACTIVE_LIST';

export const FETCH_CURRENT_DEAL = '@/deal/FETCH_CURRENT_DEAL';
export const SET_CURRENT_DEAL = '@/deal/SET_CURRENT_DEAL';

export const SET_DEAL_HISTORY = '@/deal/SET_DEAL_HISTORY';
export const FETCH_DEAL_HISTORY = '@/deal/FETCH_DEAL_HISTORY';

export const SET_AVAILABLE_MANAGER = '@/deal/SET_AVAILABLE_MANAGER';
export const FETCH_AVAILABLE_MANAGER = '@/deal/FETCH_AVAILABLE_MANAGER';

export const SET_AVAILABLE_CLIENT = '@/deal/SET_AVAILABLE_CLIENT';
export const FETCH_AVAILABLE_CLIENT = '@/deal/FETCH_AVAILABLE_CLIENT';

export const CHANGE_MANAGER = '@/deal/CHANGE_MANAGER';
export const CHANGE_CLIENT = '@/deal/CHANGE_CLIENT';

export const FETCH_DEAL_ITEM = '@/deal/FETCH_DEAL_ITEM';
export const SET_DEAL_ITEM = '@/deal/SAVE_DEAL_ITEM';

export const CHANGE_DEAL_ITEM = '@/deal/CHANGE_DEAL_ITEM';

export const ARCHIVE_HISTORY_ITEM_STATUS = '@/deal/ARCHIVE_HISTORY_ITEM_STATUS';
export const ARCHIVE_HISTORY_ITEM_RESULT = '@/deal/ARCHIVE_HISTORY_ITEM_RESULT';

export const CHANGE_FISCAL_YEAR = '@/deal/CHANGE_FISCAL_YEAR';

export const FETCH_LOST_REASONS = '@/deal/FETCH_LOST_REASONS';
export const SET_LOST_REASONS = '@/deal/SET_LOST_REASONS';

export const FETCH_ALL_DEALS_LIST = '@/deal/FETCH_ALL_DEALS_LIST';
export const SET_ALL_DEALS_LIST = '@/deal/SET_ALL_DEALS_LIST';
export const CLEAR_ALL_DEALS_LIST = '@/deal/CLEAR_ALL_DEALS_LIST';
export const SET_ALL_DEALS_FILTER = '@/deal/SET_ALL_DEALS_FILTER';
export const CLEAR_ALL_DEALS = '@/deal/CLEAR_ALL_DEALS';

export const CHANGE_RESULT_DATE = '@/deal/CHANGE_RESULT_DATE';
export const CHANGE_STATUS_DATE = '@/deal/CHANGE_STATUS_DATE';
