import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import { triggers } from '@/redux/deal/actions';
import Form from './Form';
import moment from 'moment';
import { SERVER_DATE_TIME_FORMAT } from '@/constants/date-time';
import { HISTORY_ITEM_TYPES } from '../../history/constants';
import t from '@/utilities/translate';

const ChangeDate = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		dealId,
		itemId,
		data,
		type,
		changeResultDate,
		changeStatusDate
	} = props;

	const closeModal = ({ date }) => {
		const formattedDate = date ? moment(date).format(SERVER_DATE_TIME_FORMAT) : '';

		if (type === HISTORY_ITEM_TYPES.result) {
			changeResultDate({ dealId, resultId: itemId, date: formattedDate });
		} else if (type === HISTORY_ITEM_TYPES.status) {
			changeStatusDate({ dealId, statusId: itemId, date: formattedDate });
		}

		closeModalFn();
	};

	return (
		<Modal {...modalProps} title={`${t('deals/userList/action')} - ${data.action}`} footer={null}>
			<Form
				initialValues={{ date: data.date ? new Date(data.date) : null }}
				onSubmit={closeModal}
			/>
		</Modal>
	);
};

export default connect(
	null,
	{
		...triggers
	}
)(ChangeDate);
